import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/features/account/account.service';
import { DashboardService } from 'src/app/features/dashboard/dashboard.service';
import { ProfileService } from 'src/app/features/profile/profile.service';
import { GetProfileData } from 'src/app/Models/OrderModel';
import { UserData } from 'src/app/Models/ProfileModel';
import { logoutObject, NotificationRequestObject, ServiceDTO, UserLoginResponse } from 'src/app/Models/UserModel';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  toggleFlag: boolean = false;
  sidebarflag: any;
  minutes: any;
  seconds: any;
  timerOn: boolean = true;
  showTimer: boolean = false;
  public showNotofication: boolean = false;
  userLoginResponse = new UserLoginResponse();
  notificatnTab: any;
  updateErrMsg: string = '';
  promotionaErrMsg: string = '';
  notificationList: any;
  tabIndex: number = 0;
  serviceDTO: ServiceDTO;
  notificationRequestObject: NotificationRequestObject;
  notificationCount: any;
  customLoadingTemplate: any;
  loading: boolean = false;

  getProfileData: GetProfileData;
  userData: UserData;
  profilePicLink: any;
  selectedProfilePicFlag: boolean;
  logoutObject: logoutObject;
  IPAddress : string;

  constructor(private sharedService: SharedService, private router: Router, private dashboardService: DashboardService, private profileService: ProfileService, private accountService : AccountService) {
    this.sidebarflag = this.sharedService.glbFlags['mobileSideBar'];
    this.sharedService.setTimer.subscribe((timerValue: any) => {
      if (timerValue) {
        this.timer(timerValue);
      }
    });
  }

  ngOnInit(): void {
    this.userLoginResponse = this.sharedService.getLoggedInUser();
    this.sidebarflag = this.sharedService.glbFlags['mobileSideBar'];
    const timerValue = sessionStorage.getItem('timer');
    if (timerValue) {
      this.sharedService.isOtpGenFlag.emit(false);
      this.sharedService.setShowOTPFlag(false);
      this.timer(timerValue);
    }
    this.getProfileInfo();
    this.getAllNotification(0);
    this.getNotificationCount();
    this.getIpAddress();
  }

  getProfileInfo() {
    this.loading = true;
    this.getProfileData = new GetProfileData();
    var encryptedMobileNo = this.sharedService.encryptData(this.userLoginResponse.mobileNo);
    this.getProfileData.mobileNo = encryptedMobileNo;
    this.profileService.getProfileInfo(this.getProfileData, this.userLoginResponse.mobileNo).subscribe((response: any) => {
      this.loading = false;
      this.sharedService.decryptSiteData(response);
      this.sharedService.loginUserData = response;
      if (response.userType == "Tier-1") {
        this.userData = response;
        // this.sharedService.loginUserData = response;
        if (this.userData?.profilePicLink) {
          this.profilePicLink = (this.userData?.profilePicLink);
        }
      } else {
        this.userData = response;
        // this.sharedService.loginUserData = response;
        if (this.userData?.profilePicLink) {
          this.profilePicLink = (this.userData?.profilePicLink);
        }
      }
      const fullName = this.userData.fullName.split(' ');
      //const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      const initials = fullName.shift().charAt(0) ;
      console.log(initials)
      this.userData.initials = initials
    }, (error: any) => {
      this.loading = false;
    });
  }
  ngAfterContentChecked() {
    this.selectedProfilePicFlag = this.sharedService.selectedProfilePicFlag;
    if (this.selectedProfilePicFlag == true) { this.profilePicLink = (this.sharedService.profilePicLink).toString().replace("https", "http"); }
  }
  onLogout() {
    this.sharedService.setSessionTimeout(false);
    //this.sharedService.removeToken();
    this.sharedService.isOtpGenFlag.emit(true);
    this.sharedService.setShowOTPFlag(true);
   
    this.logoutObject = new logoutObject();
    this.logoutObject.deviceType = 'WEB';
    this.logoutObject.mobileNo = this.userLoginResponse.mobileNo;
    this.logoutObject.ip_address_imei = this.IPAddress;
    this.logoutObject.mode = "WEB";
    this.logoutObject.url_pagename = "logout";

    this.loading = true;
    this.profileService.logoutAPI(this.logoutObject, this.userLoginResponse.mobileNo).subscribe((response: any) => {
      this.sharedService.setSessionTimeout(false);
      this.sharedService.removeToken();
      this.sharedService.isOtpGenFlag.emit(true);
      this.sharedService.setShowOTPFlag(true);
      sessionStorage.clear();
     this.sharedService.selectedSiteCodeFlag = false;
      this.router.navigate(['/login']);
      this.loading = false;
    }, (error: any) => {
      this.loading = false;
      this.sharedService.setSessionTimeout(false);
      this.sharedService.removeToken();
      this.sharedService.isOtpGenFlag.emit(true);
      this.sharedService.setShowOTPFlag(true);
      sessionStorage.clear();
     this.sharedService.selectedSiteCodeFlag = false;
      this.router.navigate(['/login']);
      this.loading = false;
    });

  }

  timer(remaining: any) {
    this.showTimer = true;
    this.minutes = Math.floor(remaining / 60);
    this.seconds = remaining % 60;

    this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
    this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;
    remaining -= 1;
    if (remaining >= 0 && this.timerOn) {
      setTimeout(() => {
        this.timer(remaining);
        sessionStorage.setItem('timer', remaining);
      }, 1000);
      return;
    } else {
      this.showTimer = false;
      this.sharedService.isOtpGenFlag.emit(true);
      this.sharedService.setShowOTPFlag(true);
      sessionStorage.removeItem('timer');
    }
    if (!this.timerOn) {
      // Do validate stuff here
      return;
    }
    // Do timeout stuff here
    // alert('Timeout for otp');
  }

  toggleSideBar(event) {
    event.stopPropagation();
    let flagCheck =  this.sharedService.getToggleFlag();
    if (flagCheck) {
      this.sidebarflag= false;
      this.sharedService.setToggleFlag(false);
      this.sharedService.toggleFlag.emit(false);
    } else {
      // this.sharedService.glbFlags['mobileSideBar'] = true;
      this.sharedService.setToggleFlag(true);
      this.sharedService.toggleFlag.emit(true);
      this.sidebarflag= true;
    }
  }

  closePanels(event) {
    event.stopPropagation();
    this.sharedService.glbFlags['mobileSideBar'] = false;
  }

  toggleNotification(event) {
    event.stopPropagation();
    this.getAllNotification(0);
    if (this.showNotofication) {
      this.showNotofication = false;
    } else {
      // this.getAllNotification();
      this.showNotofication = true;
    }
  }
  handleChange(event: any) {
    this.tabIndex = event.index;
    this.getAllNotification(this.tabIndex);
  }
  private getAllNotification(tabIndex: any) {
    this.updateErrMsg = '';
    this.promotionaErrMsg = '';
    if (tabIndex === 0) {
      this.notificatnTab = 'UPDATES'
    } else if (tabIndex === 1) {
      this.notificatnTab = 'PROMOTIONAL'
    }
    this.serviceDTO = new ServiceDTO();
    this.serviceDTO.notificationType = this.notificatnTab;
    this.notificationRequestObject = new NotificationRequestObject();
    this.notificationRequestObject.serviceDTO = this.serviceDTO;
    this.notificationList = [];
    this.dashboardService.getAllNotifications(this.userLoginResponse.mobileNo, this.notificatnTab,this.userLoginResponse.mobileNo,this.userLoginResponse.memberSapCode).subscribe((response: any) => {
      // this.notificationList = response['serviceDTO'].notificationResponse;
      this.notificationList = response;
      if (this.notificationList.length === 0) {
        if (tabIndex === 0) {
          this.updateErrMsg = 'No data found';
        } else if (tabIndex === 1) {
          this.promotionaErrMsg = 'No data found';
        }
      }
    }, (error: any) => {
      // error msg
    });
  }
  notificationNavigation(item) {
    let readStatus;
    if (this.tabIndex == 0) {
      readStatus = item.readStatus;
    } else {
      readStatus = item.read;
    }
    if (readStatus) {
      if (this.tabIndex == 0) {
        this.router.navigate(['order/dispatchDetails'],
          {
            queryParams:
            {
              invoiceNo: item.invoiceNo,
              invoiceUom: item.invoiceUom,
              ratingFlag: false
            }
          }
        );
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        };
      } else {
        if (item.pageUrl == "Tips and Tools") {
          this.router.navigate(['/tipsAndTools']);
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          };
        } else if (item.pageUrl == "Announcement") {
          this.router.navigate(['/announcement']);
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          };
        } else if (item.pageUrl != "Tips and Tools" && item.pageUrl != "Announcement") {
          window.open(item.pageUrl, "_blank");
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return true;
          };
        }
      }
    } else {
      this.loading = true;
      this.dashboardService.getmarkAsRead('', item.notificationId,this.userLoginResponse.mobileNo,this.userLoginResponse.memberSapCode,).subscribe((response: any) => {
        if (this.tabIndex == 0) {
          this.router.navigate(['order/dispatchDetails'],
            {
              queryParams:
              {
                invoiceNo: item.invoiceNo,
                invoiceUom: item.invoiceUom,
                ratingFlag: false
              }
            }
          );
          this.loading = false;
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          };
        } else {
          if (item.pageUrl == "Tips and Tools") {
            this.router.navigate(['/tipsAndTools']);
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
              return false;
            };
          } else if (item.pageUrl == "Announcement") {
            this.router.navigate(['/announcement']);
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
              return false;
            };
          } else if (item.pageUrl != "Tips and Tools" && item.pageUrl != "Announcement") {
            window.open(item.pageUrl, "_blank");
          }
        }
        this.loading = false;
      }, (error: any) => {
        this.loading = false;
      });
    }
  }
  getNotificationCount() {
    this.dashboardService.getUnreadCount(this.userLoginResponse.mobileNo,this.userLoginResponse.memberSapCode,).subscribe((response: any) => {
      this.notificationCount = '';
      this.notificationCount = response.unReadCount;
    }, (error: any) => {
      // error msg
    });
  }

  public getIpAddress(){
    this.accountService.getIPAddress().subscribe((response : any)=>{
      try{
      this.IPAddress=response.ip;
      }
      catch(e){
        this.IPAddress="";
      }
    
    })
  }
}
