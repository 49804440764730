export class SiteObject {
    siteId: string;
    groupCustCode: string;
    siteName: string;
}
export class GetProfileData {
    mobileNo: string;
}
export class GetDispatchOrderObject {
    userId: string;
}
export class GetDispatchDetailsObject {
    invoiceNo: string;
}
export class EpodRequestObject {
    orderId: string;
    invoiceNo: string;
    vehicleNo: string;
    userMasterId: number;
    orderUOM: string;
    suppliedQty: number;
    regMobileNo: string;
    receivedQty: number;
    invoiceDateTime: string;
    dispatchDateTime: string;
    invoiceURL: string;
    transporterLRURL: string;
    weighingSlipURL: string;
    otp: string;
    sealNo: string;
    sealValue: string;
    flaggedUser: boolean
}
export class GenrateOTPForEpodObject {
    invoiceNo: string;
    mobileNo: string;
    userId: string;
}
export class ValidateEpodOtpObject {
    mobileNo: string;
    otp: string;
}
export class EpodDetails {
    invoiceNo: string;
    mobileNo: string;
    userType: string;
}
export class SubmitFeedbackDetails {
    dispatchSource: string;
    feedbackComment: string;
    feedbackOptionList: SubmitFeedbackOptions[]
    invoiceNumber: string;
    orderId: number;
    rating: number
    shipToId: string;
    shipToName: string;
    userId: number;
    invoiceNoList:any;
    mode : string;
    ip_address_imei : string;
    url_pagename : string;
}
export class FeedbackOptions {
    optionId: number;
    optionValue: string;
    isClickedFlag: boolean = false;
}
export class DispatchDetailsObject {
    orderId: string;
    orderNo: string;
    invoiceNo: string;
    shipToName: string;
    createdBy: string;
    orderQuantity: string;
    orderUOM: string;
    invoiceQuantity: string;
    invoiceUOM: string;
    orderDateTime: Date;
    orderAckDateTime: Date;
    vehicleNo: string;
    sourceAllocateDateTime: Date;
    vehicleAssignDateTime: Date;
    truckAssignDateTime: Date;
    dispatchDateTime: Date;
    geofenceHitDatetime: Date;
    epodDateTime: Date;
    checkInDateTime: Date;
    checkoutDateTime: Date;
    epodStatus: string;
    destinationLocation: string;
    ratings: string;
    epodDoneBy: string;
    contactDetails: ContactDetails;
    productName: string;
    contactDriver: string;
    driverDocuments: DriverDocuments;
    eta: Date;
    isDisabled: boolean = false;
    isItrackPresent: boolean = false;
    epodQuantity:string;
    totalOffloadedQuantity:string;
}
export class DriverDocuments {
    transportLRSlipurl: string;
    weighingSlipUrl: string;
    additionalDocUrl: string;
}
export class ContactDetails {
    contactNo: number;
    contactPerson: string;
    deliveryAddress: string;
    specialInstructions: string;
}

export class GenrateOTPForEPOD {
    mobileNo: string;
    invoiceNo: string;
    userId: number;
    ip_address_imei: string;
    mode: string;
    url_pagename: string;
    

}
export class ValidateOtpForEPODObject {
    mobileNo: string;
    otp: string;
    ip_address_imei: string;
    mode: string;
    url_pagename: string;
}

export class RatingModel {
    orderId: string;
    invoiceNumber: string;
    userId: number;
    invoiceNoList: any;
}
export class RatingDetails {
    orderId: number;
    rating: number;
    feedbackComment: string;
    invoiceNumber: string;
    userId: number;
    feedbackOptionList: FeedbackOptions[] = []
}
export class SubmitFeedbackOptions {
    optionId: number;
    optionValue: string;
}
export class iTrackObject {
    applicationName: string;
    invoiceNo: string;
}
export class ePodPendingDetailsObject {
    orderId: string;
    orderNo: string;
    orderDateTime: Date;
    dispatchDateTime: Date;
    invoiceNo: Date;
    invoiceQuantity: string;
    invoiceDateTime: Date;
    invoiceUOM: string;
    geofenceHitDatetime: Date;
    destinationLocation: string;
    productName: string;
    isGeoFenceHit: boolean;
    epodStatus: string;
    epodDoneBy: string;
    rating: string;
    epodDateTime: string
}
export class GetEPODDetails {
    invoiceNo?: string;
}
export class SubmitEPODObject {
    confirmationDate: any;
    customerName: any;
    deliverySource: any;
    epodDateTime: any;
    flaggedUser: any;
    invoiceNo: any;
    invoiceDateTime: any;
    invoiceQuantity: any;
    otp: any;
    additionalDocURL: any;
    orderId: any;
    orderNo: any;
    orderDateTime: any;
    orderUom: any;
    receivedQty: any;
    recQty: any;
    regMobileNo: any;
    shipFromId: any;
    shipFromName: any;
    siteId: any;
    suppliedQty: any;
    transporterLRURL: any;
    // userMasterId: any;
    vehicleNo: any;
    weighingSlipURL: any;
    sealNo: any;
    sealValue: any;
    receivedQtyUOM: any;
    invoiceUom: any;
    mobileNo: string;
    userType: string;
    userId : number;
    epoddetailsVo:any = [];
    totalInvQty:any;
    sigleInvoice:string;
    mode : string;
    ip_address_imei : string;
    url_pagename : string;

}
export class SubmitObjetEPOD {
    orderId: any;
    orderDateTime: any;
    invoiceNo: any;
    invoiceDateTime: any;
    invoiceQuantity: any;
    invoiceUom: any;
    orderUom: any;
    receivedQty: any;
    invoiceURL: any;
    transporterLRURL: any;
    weighingSlipURL: any;
    sealNo: any;
    sealValue: any;
    flaggedUser: any
}
export class EPODDetailsObject {
    map(arg0: (item: any) => void) {
      throw new Error('Method not implemented.');
    }
    orderId?: number
    orderNo?: string;
    customerName?: string;
    invoiceNo?: string;
    vehicleNo?: string;
    siteId?: number;
    userMasterId?: number;
    orderUom?: string;
    invoiceQty: number = 0;
    invoiceQuantity: number = 0;
    invoiceUOM?: string;
    suppliedQty?: number;
    invoiceDateTime?: string;
    orderDateTime?: string;
    receivedQty?: number;
    shipFromId?: string;
    shipFromName?: string;
    regMobileNo?: string;
    deliverySource?: string;
    invoiceURL?: string;
    transporterLRURL?: string;
    weighingSlipURL?: string;
    otp?: string;
    sealNo?: string;
    shortageApplicable?:boolean;
    invoiceUom?: string;
    flaggedUser?: string;
    rating?: string;
}
export class invoiceList {
    driverContactNo: string;
    eta: Date;
    invoiceDateTime: Date;
    invoiceNo: string;
    invoiceQuantity: string;
    invoiceUOM: string;
    truckNo: string;
    isDisabled: boolean = false;
    isItrackPresent: boolean = false;
    rsnInvoiceList:string;
}
export class dispatchedMTDVo {
    dispatchedMTDQuantity: any;
    dispatchedMTDOrderList: Array<any>;
}
export class dispatchedTodayVo {
    dispatchedTodayQuantity: any;
    dispatchedTodayOrderList: Array<any>;
}
export class dispatchedYesterdayVo {
    dispatchedYesterdayOrderList: Array<any>;
    dispatchedYesterdayQuantity: any;
}

export class OrderListObject {
    orderId: number;
    orderNo: string;
    orderDateTime: string
    dispatchDateTime: string;
    invoiceNo: string;
    invoiceDateTime: string
    invoiceQuantity: string;
    invoiceUOM: string;
    geofenceHitDatetime: string;
    sourceLocation: string;
    destinationLocation: string;
    productName: string;
    siteId: number;
    userMasterId: string;
    epodStatus: string;
    geoFenceHit: boolean;
    rating: string;
    shipToName: string;
    epodDoneBy: String;
    epodDateTime: string;
    isGeoFenceHit: string;
    flaggedUser: string;
    constructor() {
        this.orderId = 0;
        this.orderNo = '';
        this.orderDateTime = '';
        this.dispatchDateTime = '';
        this.invoiceNo = '';
        this.invoiceDateTime = '';
        this.invoiceQuantity = '';
        this.invoiceUOM = '';
        this.geofenceHitDatetime = '';
        this.sourceLocation = '';
        this.destinationLocation = '';
        this.productName = '';
        this.siteId = 0;
        this.userMasterId = '';
        this.epodStatus = '';
        this.geoFenceHit = false;
        this.rating = '';
        this.shipToName = '';
        this.epodDoneBy = '';
        this.epodDateTime = '';
        this.isGeoFenceHit = '';
        this.flaggedUser = '';
    }
}
export class GetInvoivecopyObject {
    invoiceNo: string;
    isInvoice: boolean;
}

export class FireBaseNotification {
    mobileNo: string;
    userType: string;
    deviceFcmToken: string;
    mode : string;
    url_pagename : string;
    ip_address_imei : string;
}

export class GetCreditNoteObject {
    userType: string;
    invoiceNo: any;
    isInvoice: boolean;
}
export class GenrateOTPForTDS {
    mobileNo: string;   
    userId: number;
}
export class GenrateOTPForTdsObject {   
    mobileNo: string;
    userId: string;
}